import { ItemPhotoDto, ItemThumbnailSize } from '@marketplace-web/domain/item-data'
import {
  BumpableItemDto,
  BumpMinimumPriceDto,
  BumpMotivationDto,
  BumpOptionDto,
  BumpOptionsDto,
  BumpOrderDto,
  BumpOrderItemDto,
} from 'types/dtos'
import {
  BumpableItemModel,
  BumpMinimumPriceModel,
  BumpMotivationModel,
  BumpOptionModel,
  BumpOptionsModel,
  BumpOrderItemModel,
  BumpOrderModel,
} from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'
import { transformItemBoxDto } from './item-box'

export const transformBumpMinimumPrice = (dto: BumpMinimumPriceDto): BumpMinimumPriceModel => ({
  total: transformCurrencyAmountDto(dto.total),
  payable: transformCurrencyAmountDto(dto.payable),
  discount: transformCurrencyAmountDto(dto.discount),
  hasFreeBump: dto.has_free_bump,
})

const transformBumpOrderItem = (dto: BumpOrderItemDto): BumpOrderItemModel => ({
  itemId: dto.item_id,
  title: dto.title,
  imageUrl: dto.image_url,
  total: transformCurrencyAmountDto(dto.total),
  payable: transformCurrencyAmountDto(dto.payable),
  discount: transformCurrencyAmountDto(dto.discount),
  discounted: transformCurrencyAmountDto(dto.discounted),
})

export const transformBumpOrderItems = (
  dtos: Array<BumpOrderItemDto> = [],
): Array<BumpOrderItemModel> => dtos.map(transformBumpOrderItem)

export const transformBumpUpOrder = (dto: BumpOrderDto): BumpOrderModel => ({
  id: dto.id,
  status: dto.status,
  freeCount: dto.free_count,
  totalCount: dto.total_count,
  total: transformCurrencyAmountDto(dto.total),
  payIn: transformCurrencyAmountDto(dto.pay_in),
  payable: transformCurrencyAmountDto(dto.payable),
  discount: transformCurrencyAmountDto(dto.discount),
  discounted: transformCurrencyAmountDto(dto.discounted),
  freePushUpsValue: transformCurrencyAmountDto(dto.free_push_ups_value),
  discountPercentage: dto.discount_percentage,
  pushUpOrderItems: transformBumpOrderItems(dto.push_up_order_items),
  salesTax: dto.sales_tax ? transformCurrencyAmountDto(dto.sales_tax) : null,
  isTaxCoverageAvailable: dto.tax_coverage_available ?? null,
  isTaxCovered: dto.tax_covered ?? null,
})

export const transformBumpOption = ({
  days,
  payable,
  description,
  default: isDefault,
  best_value,
  international,
  countries,
  total,
  discount,
  discounted,
  sales_tax,
  push_up_order_items,
}: BumpOptionDto): BumpOptionModel => {
  return {
    id: `${days}${international ? '-int' : ''}`,
    days,
    isDefault,
    description,
    isBestValue: !!best_value,
    international,
    countries,
    payable: transformCurrencyAmountDto(payable),
    total: transformCurrencyAmountDto(total),
    discount: transformCurrencyAmountDto(discount),
    discounted: transformCurrencyAmountDto(discounted),
    salesTax: sales_tax ? transformCurrencyAmountDto(sales_tax) : undefined,
    pushUpOrderItems: push_up_order_items.map(transformBumpOrderItem),
  }
}

export const transformBumpOptions = (dtos: Array<BumpOptionDto>): Array<BumpOptionModel> =>
  dtos.map(transformBumpOption)

export const transformBumpMotivation = (dto: BumpMotivationDto): BumpMotivationModel => ({
  title: dto.title,
  description: dto.description,
  highlight: dto.highlight,
})

export const transformBumpsOptions = (dto: BumpOptionsDto): BumpOptionsModel => ({
  motivation: dto.motivation ? transformBumpMotivation(dto.motivation) : undefined,
  options: dto.options ? transformBumpOptions(dto.options) : [],
  hasLocalOptions: dto.has_local_options,
})

export const getThumbnailFromBumpableItemDto = (
  photo: ItemPhotoDto,
  size = ItemThumbnailSize.Large,
) => {
  return photo.thumbnails.find(thumb => thumb.type === size)?.url || null
}

export const transformBumpItemDtoToModel = (item: BumpableItemDto): BumpableItemModel => ({
  id: item.id,
  title: item.title,
  thumbnailUrl: item.photo ? getThumbnailFromBumpableItemDto(item.photo) : null,
  price: transformCurrencyAmountDto(item.price),
  dominantColor: item.photo?.dominant_color_opaque || null,
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})

export const transformBumpItems = (items: Array<BumpableItemDto>): Array<BumpableItemModel> =>
  items.map(transformBumpItemDtoToModel)
